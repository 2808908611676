/* eslint-disable */
import $ from 'jquery';
import 'jquery-mask-plugin';
import ymaps from 'ymaps';

window.$ = $;
window.jQuery = $;

$(() => {
	const map = $('#map');
	if (map.length) {
		// const coords = map.data('coords').split(',');

		ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU').then((maps) => {
            const coords = [59.200113, 39.959040];
            const myMap = new maps.Map('map', {
                center: window.matchMedia('(max-width:991px)').matches ? coords : [59.200515, 39.897659],
                zoom: 13,
                controls: []
            }, {
                searchControlProvider: 'yandex#search',
            });

            const myPlacemark = new maps.Placemark(coords, {}, {
                preset: 'islands#yellowIcon'
            });
  
            myMap.geoObjects.add(myPlacemark);


            myMap.behaviors.disable('scrollZoom');
            map.addClass('noZoom');
            myMap.events.add('click', () => {
                if (map.hasClass('noZoom')) {
                    map.removeClass('noZoom').addClass('yesZoom');
                    myMap.behaviors.enable('scrollZoom');
                } else {
                    map.removeClass('yesZoom').addClass('noZoom');
                    myMap.behaviors.disable('scrollZoom');
                }
            });

            const isMobile = {
                Android: () => (navigator.userAgent.match(/Android/i)),
                BlackBerry: () => (navigator.userAgent.match(/BlackBerry/i)),
                iOS: () => (navigator.userAgent.match(/iPhone|iPad|iPod/i)),
                Opera: () => (navigator.userAgent.match(/Opera Mini/i)),
                Windows: () => (navigator.userAgent.match(/IEMobile/i)),
                any: () => (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows())
            };

            if (isMobile.any()) {
                myMap.behaviors.disable('drag');
            }

		}).catch(error => console.log('Failed to load Yandex Maps', error));
	}
});

$(() => {
    $('.callback .form').on('submit', function(e) {
        e.preventDefault();
        const values = $(this).serialize();

        $.get(`/bot/bot.php?${values}`, () => {
            $('.callback .form')[0].reset();
            alert('Сообщение отправлено');

        });
        return false;
    });
});